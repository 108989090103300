import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import LogoRus from './icon/Logo-rus';
import { Box } from '@mui/material';

// Lazy-loaded pages and components
const Home = lazy(() => import('./pages/Home'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Widget = lazy(() => import('./components/Widget'));

const ServiceDetails = lazy(() =>
  import('./pages/ServiceDetails/ServiceDetails')
);

function App() {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <LogoRus className="spiner" width="100%" height="100%" />
        </Box>
      }
    >
      <Widget />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/:id" element={<ServiceDetails />} />
        <Route path="*" element={<Home />} />
      </Routes>

      <Footer />
    </Suspense>
  );
}

export default App;
