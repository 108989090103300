export const ru = {
  header: {
    inputLoaction: {
      placeholder: 'Укажите город',
    },
    selectInput: {
      individuals: 'Физическим лицам',
      legal: 'Юридическим лицам',
    },
  },
  maine: {
    title: {
      text: 'Услуги детектива',
      statusLegal: 'Юридическим лицам',
      statusPhysical: 'Физическим лицам',
    },
    subTitle: {
      text: 'поручи нашим детективам своё дело и получи первые результаты за',
      time: '24 ЧАСА',
    },
    button: {
      text: 'Записаться на консультацию',
    },
  },
  services: {
    title: {
      text: 'Услуги детектива',
    },
    selectInput: {
      individuals: 'Физическим лицам',
      legal: 'Юридическим лицам',
    },
    card: {
      buttonOrder: {
        text: 'Заказать',
      },
      buttonDetails: {
        text: 'Подробнее',
      },
    },
    description: {
      button: {
        text: 'назад',
      },
    },
  },
  steps: {
    title: 'Как мы оказываем услуги детектива?',
    numberOne: {
      title: 'Звонок',
      description: 'Вы записываетесь к нам на консультацию',
    },
    numberTwo: {
      title: 'Консультация',
      description: 'Вы получаете консультацию нашего специалиста',
    },
    numberThree: {
      title: 'Инструкция',
      description:
        'Вы получаете чёткие и понятные пошаговые инструкции о том, что следует сделать для решения Вашей проблемы',
    },
    numberFour: {
      title: 'Информация',
      description:
        'Вы получаете чёткую и полную информацию о стоимости наших услуг на тот случай, если Вы пожелаете заказать их нашим специалистам',
    },
    numberFive: {
      title: 'Заказ',
      description:
        'Вы делаете заказ с необходимым перечнем услуг и получаете их в полном объеме, гарантировано',
    },
  },
  gallery: {
    title: 'Наши частные детективы работают как онлайн, так и оффлайн.',
  },
  footer: {
    location: {
      title: 'Адрес',
      link: {
        text: 'C/ de les Barques, 3, Ciutat Vella, 46002 Valencia, Valencia, Spain',
      },
    },
    mail: {
      title: 'Почта',
    },
    socialLinks: {
      title: 'Социальные ссылки',
    },
  },
  modal: {
    title: 'Заказать услугу',
    inputName: {
      invalidMessage: 'Неверный формат имени минимум 4 символа',
      errorMessage: 'Введите имя',
      label: 'Имя',
    },
    inputPhone: {
      invalidMessage: 'Неверный формат номера',
      errorMessage: 'Введите номер телефона',
      label: 'Номер телефона',
    },
    buttonSubmit: 'Отправить',
  },
};
